import React from "react";
import { Header, Footer } from "../../Components";
import { Outlet } from "react-router-dom";

const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <div>
        <Header />
        <main className="min-h-[calc(100vh-144px)] bg-white">
          <Outlet />
        </main>
        <div><Footer /></div>
      </div>
    </React.Fragment>
  );
};

export default Layout;
