import React from "react";
import { BsPhone } from 'react-icons/bs';
import { MdAlternateEmail } from 'react-icons/md';
import { FaPhone } from "react-icons/fa";

const ReachOut = () => {
  return (
    <div>
      <div className="self-center text-3xl font-extrabold text-primary pb-6">
        <h1>REACH OUT TO US</h1>
      </div>

      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="flex flex-col space-x-4 border-2 w-full md:w-1/2">
          <div>
            <iframe
              title="Mumbai Office"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.406490952836!2d73.2298033116891!3d19.177440081973323!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be793453b6e2f2f%3A0xcb9e1e90796af024!2sShree%20Electricals!5e0!3m2!1sen!2sin!4v1681566440999!5m2!1sen!2sin"
              width="300"
              height="300"
              className="b-0 w-full"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade">

            </iframe>
          </div>
          <div className="px-2 py-4">
            <div className="text-xl font-bold pb-2">Mumbai Office</div>
            <div className="text-gray-700">
              Om Sai Paradise, Shop No. 12, Bhiwandi - Wada Road,
              Near Federal Bank Ambadi, Ambadi, Bhiwandi, Thane - 421302
            </div>
          </div>
        </div>
        <div className="flex flex-col space-x-4 border-2 w-full md:w-1/2">
          <div>
            <iframe
              title="Mumbai Office"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.2996740407325!2d73.82595449678954!3d18.51535520000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf8ffac56e2f%3A0xed739b7d8b0efabe!2sSoujanya%20Cooperative%20Housing%20Society!5e0!3m2!1sen!2sin!4v1681567569039!5m2!1sen!2sin"
              width="300"
              height="300"
              className="b-0 w-full"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </div>
          <div className="px-2 py-4">
            <div className="text-xl font-bold pb-2">Pune Office</div>
            <div className="text-gray-700">
              Saujanya Apartment, 79/A1, Lane 15,Prabhat Road,
              Near Symbiosis School, Pune - 411004
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <h1 className="self-center text-2xl font-bold text-primary pb-4">
          Our Representatives
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div className="p-4 shadow-sm border-2 rounded-sm">
            <div className="flex flex-col gap-1">
              <div className="text-primary font-medium text-xl pb-2">
                Mr. Pranjal Chaudhari
              </div>
              <div className="flex items-center">
                <BsPhone size={20} />
                <span className="pl-2">+91 8796227037</span>
              </div>
              <div className="flex items-center">
                <MdAlternateEmail size={20} />
                <span className="pl-2 whitespace-normal break-words">pranjal_14686@yahoo.co.in</span>
              </div>
              <a href="tel:+918796227037">
                <button
                  className="bg-black text-white text-sm my-2 py-2 px-4 rounded-md flex items-center"
                >
                  <FaPhone className="h-4 w-4 mr-2" />
                  Call Now
                </button>
              </a>
            </div>
          </div>

          <div className="p-4 shadow-sm border-2 rounded-sm">
            <div className="flex flex-col gap-1">
              <div className="text-primary font-medium text-xl pb-2">
                Mr. Hemant Patil
              </div>
              <div className="flex items-center">
                <BsPhone size={20} />
                <span className="pl-2">+91 9049744130</span>
              </div>
              <div className="flex items-center">
                <MdAlternateEmail size={20} />
                <span className="pl-2 whitespace-normal break-words">hemantpatil4141@gmail.com</span>
              </div>
              <a href="tel:+919049744130">
                <button
                  className="bg-black text-white text-sm my-2 py-2 px-4 rounded-md flex items-center"
                >
                  <FaPhone className="h-4 w-4 mr-2" />
                  Call Now
                </button>
              </a>
            </div>
          </div>
          <div className="p-4 shadow-sm border-2 rounded-sm">
            <div className="flex flex-col gap-1">
              <div className="text-primary font-medium text-xl pb-2">
                Mr. Pradip Patil
              </div>
              <div className="flex items-center">
                <BsPhone size={20} />
                <span className="pl-2">+91 9975015316</span>
              </div>
              <div className="flex items-center">
                <MdAlternateEmail size={20} />
                <span className="pl-2 whitespace-normal break-words">pradeep.patill1414@gmail.com</span>
              </div>
              <a href="tel:+919975015316">
                <button
                  className="bg-black text-white text-sm my-2 py-2 px-4 rounded-md flex items-center"
                >
                  <FaPhone className="h-4 w-4 mr-2" />
                  Call Now
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReachOut;