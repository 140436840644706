import React from "react";
import aboutus from "../Assets/images/aboutus.webp";
import { Clients } from "../Components";
import * as routes from "../routes";

const clients = () => {
    return (
        <>
            <div className="relative">
                <img className="w-full h-60 md:h-96" src={aboutus} alt="aboutUsImage" />
                <div className="flex flex-col justify-center items-center absolute bottom-0 left-0 h-full w-full bg-black bg-opacity-60">
                    <h3 className="text-white text-center text-5xl font-extrabold">
                        Our Clients
                        <p className="h-1 bg-white" />
                    </h3>
                </div>
            </div>
            <div className="p-5 md:px-12 md:py-10">
                <div className="text-3xl font-bold pb-2 text-primary">
                    Our Esteemed Clients
                </div>
                <div className="text-gray-800 text-lg">
                We value our clients at Phoenix Electricals and strive to exceed their expectations 
                with quality workmanship, excellent customer service, and timely project completion. 
                Our commitment is to put our clients' electrical needs first, building lasting 
                relationships based on trust and delivering top-notch electrical solutions for both 
                residential and commercial projects.
                </div>
                <Clients />
                <div className="flex gap-4 py-4 justify-center">
                    <a href={routes.CONTACTUS} className="mt-4 bg-primary hover:bg-black text-white py-2 px-4 text-center rounded">
                        Contact Us
                    </a>
                    <a href={routes.CLIENTS} className="mt-4 bg-primary hover:bg-black text-white py-2 px-4 text-center rounded">
                        Our Clients
                    </a>
                    <a href={routes.CONTRACTS} className="mt-4 bg-primary hover:bg-black text-white py-2 px-4 text-center rounded">
                        Contracts
                    </a>
                </div>
            </div>
        </>
    );
};

export default clients;