import React from "react";
import aboutus from "../Assets/images/aboutus.webp";
import solar from "../Assets/images/solar.webp";
import { ReachOut } from "../Components";
import * as routes from "../routes";

const AboutUs = () => {
    return (
        <>
            <div className="relative">
                <img className="w-full h-60 md:h-96" src={aboutus} alt="aboutUsImage" />
                <div className="flex flex-col justify-center items-center absolute bottom-0 left-0 h-full w-full bg-black bg-opacity-60">
                    <h3 className="text-white text-center text-5xl font-extrabold">
                        About Us
                        <p className="h-1 bg-white" />
                    </h3>
                </div>
            </div>
            <div className="p-5 md:px-10 md:py-10">
                <div className="w-full mx-auto bg-white border-2 overflow-hidden">
                    <div className="md:flex">
                        <div className="md:shrink-0 lg:max-w-lg">
                            <img className="h-48 w-full object-cover block md:hidden" src={solar} alt="Solar Consultation" />
                            <img className="w-80 lg:w-full h-full object-cover hidden md:block" src={solar} alt="Solar Consultation" />
                        </div>
                        <div className="flex flex-wrap p-8 text-base text-justify text-secondary">
                            <div>
                                <p className="mt-2"><span className="text-lg font-bold">Phoenix Electricals</span> is a turnkey Electrical Solutions company working in the Electrical distribution sector and having experience in Government and Private sectors with varied experience in Liasoning, Design, Procurement, Erection, Testing and Commissioning of Substations, Distribution Transformer Centers, Overhead lines, Underground cables and RMU installation works.</p>
                                <p className="mt-2">Even though the company is relatively new we have slowly built up a brand name both in cross section of Indian market with presence all over Maharashtra and now recently diversifying in Goa and Karnataka.</p>
                                <p className="mt-2">We have adequate inhouse experience people with senior team having years of experience in this sector. The company has a dedicated inhouse manpower for execution of various projects and also testing facilities. The company is always available round the clock for providing post installation services.</p>
                            </div>
                            <div className="flex gap-4">
                                <a href={routes.CONTRACTS} className="mt-4 bg-primary hover:bg-black text-white py-2 px-4 text-center rounded">
                                    Our Contracts
                                </a>
                                <a href={routes.CLIENTS} className="mt-4 bg-primary hover:bg-black text-white py-2 px-4 text-center rounded">
                                    Our Clients
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-10">
                    <ReachOut />
                </div>
            </div>
        </>
    );
};

export default AboutUs;