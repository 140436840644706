import React from "react";
import aboutus from "../Assets/images/aboutus.webp";
import * as routes from "../routes";

const contracts = () => {
    return (
        <>
            <div className="relative">
                <img className="w-full h-60 md:h-96" src={aboutus} alt="aboutUsImage" />
                <div className="flex flex-col justify-center items-center absolute bottom-0 left-0 h-full w-full bg-black bg-opacity-60">
                    <h3 className="text-white text-center text-5xl font-extrabold">
                        Our Contracts
                        <p className="h-1 bg-white" />
                    </h3>
                </div>
            </div>
            <div className="p-5 md:px-12 md:py-10">
                <div className="text-3xl font-bold pb-2 text-primary">
                    What we have accomplished?
                </div>
                <div className="text-gray-800 text-lg">
                    Explore our completed contracts and projects at Phoenix Electricals! We're dedicated to providing exceptional electrical solutions for a variety of industries and projects. Browse through our portfolio to see our quality workmanship and satisfied clients. Let's partner together for your next project!
                </div>
                <div className="flex flex-col items-center">
                    <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4">
                        <div className="block max-w-xs lg:max-w-sm 2xl:max-w-md my-4 md:my-6 lg:my-4 p-6 shadow-md dark:bg-neutral-700">
                            <h5 className="pb-2 text-lg font-bold leading-tight text-primary ">
                                MSEDCL OH to UG at Baramati
                            </h5>
                            <p className="mb-2 text-justify">
                                Around 90% of <span className="font-bold">650 kms</span> of HT Cable, LT Cable and RMU's completed in record time
                                of <span className="font-bold">6 months</span>. Balance 10% work should be completed in around one month time, and
                                project will be completed before schedule.
                            </p>
                        </div>

                        <div className="block max-w-xs lg:max-w-sm 2xl:max-w-md my-4 md:my-6 lg:my-4 p-6 shadow-md dark:bg-neutral-700">
                            <h5 className="pb-2 text-lg font-bold leading-tight text-primary ">
                                MSEDCL IPDS Tender
                            </h5>
                            <p className="mb-2 text-justify">
                                Complete Execution of <span className="font-bold">180 kms</span> of UG Cables for IPDS tender
                                (Integrated Power Development Scheme of Govt of India) of M/s Shree Electricals & Engineers Pvt Ltd.
                            </p>
                        </div>

                        <div className="block max-w-xs lg:max-w-sm 2xl:max-w-md my-4 md:my-6 lg:my-4 p-6 shadow-md dark:bg-neutral-700">
                            <h5 className="pb-2 text-lg font-bold leading-tight text-primary ">
                                MSEDCL OH to UG at Lonavala
                            </h5>
                            <p className="mb-2 text-justify">
                                Received tender from MSEDCL for INS Shivaji to convert OH to UG cables for <span className="font-bold">24 kms</span> at Lonavala
                            </p>
                        </div>

                        <div className="block max-w-xs lg:max-w-sm 2xl:max-w-md my-4 md:my-6 lg:my-4 p-6 shadow-md dark:bg-neutral-700">
                            <h5 className="pb-2 text-lg font-bold leading-tight text-primary ">
                                MSEDCL Infra Phase II Tender
                            </h5>
                            <p className="mb-2 text-justify">
                                Received MSEDCL's Infra Phase II tender of Padmavati division on back to back basis from M/s Rahul Cable, Pune
                            </p>
                        </div>

                        <div className="block max-w-xs lg:max-w-sm 2xl:max-w-md my-4 md:my-6 lg:my-4 p-6 shadow-md dark:bg-neutral-700">
                            <h5 className="pb-2 text-lg font-bold leading-tight text-primary ">
                                Missing Link Project Electrical Work For MSRDC at Lonavala
                            </h5>
                            <p className="mb-2 text-justify">
                                <ul className="text-left">
                                    <li>
                                        - 22/22 KV Indoor Switching Station
                                    </li>
                                    <li>
                                        - 22 KV Express Feeder 7.60 Kms
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <div className="block max-w-xs lg:max-w-sm 2xl:max-w-md my-4 md:my-6 lg:my-4 p-6 shadow-md dark:bg-neutral-700">
                            <h5 className="pb-2 text-lg font-bold leading-tight text-primary ">
                                Kirloskar Brothers Ltd
                            </h5>
                            <p className="mb-2 text-justify">
                                UG Cable and Substation for Prestigious Bhama Askhed Project
                                <ul className="text-left pt-2">
                                    <li>
                                        - 33/22 KV 5MVA Substation
                                    </li>
                                    <li>
                                        - 22 KV Express Feeder 9.55 Kms
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <div className="block max-w-xs lg:max-w-sm 2xl:max-w-md my-4 md:my-6 lg:my-4 p-6 shadow-md dark:bg-neutral-700">
                            <h5 className="pb-2 text-lg font-bold leading-tight text-primary ">
                                Other Projects
                            </h5>
                            <p className="mb-2 text-justify">
                                <ul className="text-left">
                                    <li>
                                        - MSEDCL OH to UG cable at Thane.
                                    </li>
                                    <li>
                                        - NSC work at 9 Division of MSEDCL In Maharashtra.
                                    </li>
                                    <li>
                                        - GIS Sub - Station, for MSEDCL at Mandai, Pune.
                                    </li>
                                    <li>
                                        - Cable Laying by HDD Method, for MSEDCL at Dombivili.
                                    </li>
                                </ul>
                            </p>
                        </div>
                    </div>
                    <div className="flex gap-4 py-4">
                        <a href={routes.CONTACTUS} className="mt-4 bg-primary hover:bg-black text-white py-2 px-4 text-center rounded">
                            Contact Us
                        </a>
                        <a href={routes.CLIENTS} className="mt-4 bg-primary hover:bg-black text-white py-2 px-4 text-center rounded">
                            Our Clients
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default contracts;