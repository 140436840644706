import image1 from "./overhead_lines.webp";
import image2 from "./solar.webp";
import image3 from "./substation.webp";

export default [
    {
       src: image1,
       title: "Overhead Lines",
    },
    {
        src:image2,
        title: "Solar",
    },
    {
        src: image3,
        title: "Substations"
    }
]