import React from "react";
import aboutus from "../Assets/images/aboutus.webp";
import { ContactForm, ReachOut } from "../Components";

const contactUs = () => {
    return (
        <div>
            <div className="relative">
                <img className="w-full h-60 md:h-96" src={aboutus} alt="aboutUsImage" />
                <div className="flex flex-col justify-center items-center absolute bottom-0 left-0 h-full w-full bg-black bg-opacity-60">
                    <h3 className="text-white text-center text-5xl font-extrabold">
                        Contact Us
                        <p className="h-1 bg-white" />
                    </h3>
                </div>
            </div>
            <div className="p-5 md:px-10 md:py-10 text-gray-800 text-lg">
                Our Contact Us page is where you can easily reach out to Phoenix Electricals with any questions or electrical needs.
                We value open communication with our clients and are committed to providing exceptional service from start to finish.
                Whether you prefer to contact us via phone, email, or our user-friendly form, our team is ready to assist you. Get in touch
                with us today - we're here to help!
            </div>
            <div className="lg:flex md:px-10 px-5 py-5 md:py-10 gap-4">
                <div className="w-full lg:w-8/12"><ReachOut /></div>
                <div className="w-full lg:w-4/12 px-4 justify-center"><ContactForm /></div>
            </div>
        </div>
    );
};

export default contactUs;