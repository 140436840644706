import React, { useState } from "react";
import logo from "../Assets/images/logo.webp";
import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import * as Routes from "../routes";

const Header = () => {
  const [displayMenu, setDisplayMenu] = useState(false);

  const menuItems = [
    {
      title: 'Home',
      href: Routes.HOME,
    },
    {
      title: 'About Us',
      href: Routes.ABOUT_US,
    },
    {
      title: 'Contracts',
      href: Routes.CONTRACTS,
    },
    {
      title: 'Clients',
      href: Routes.CLIENTS,
    },
    {
      title: 'Contact Us',
      href: Routes.CONTACTUS,
    },
  ]

  const isMenuItemActive = (hrefPath) => {
    const currentPath = window.location.pathname;
    return hrefPath === currentPath;
  }

  return (
    <>
      <nav className="bg-white border-gray-200 px-2 sm:px-4 py-1 md:py-4 sticky top-0 z-10 shadow-lg">
        <div className="container flex flex-wrap justify-between items-center mx-auto">
          <a href="/" className="flex items-center lg:justify-center justify-left">
            <img width={120} src={logo} alt="Phoenix Electricals"></img>
          </a>

          <div className="flex flex-row hidden md:block" id="desktop-menu">
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-5 md:mt-0 md:font-medium">
              {
                menuItems.map(menuItem => {
                  const textColor = isMenuItemActive(menuItem.href) ? 'text-primary' : 'text-gray-700'
                  return (
                    <li key={menuItem.href}>
                      <a
                        href={menuItem.href}
                        className={`block py-2 p-3 bg-blue-700 rounded md:bg-transparent text-lg ${textColor}`}
                        aria-current="page"
                      >
                        {menuItem.title}
                      </a>
                    </li>
                  )
                }
                )
              }
            </ul>
          </div>
          {
            !displayMenu &&
            <button className="md:hidden" onClick={() => setDisplayMenu(true)}>
              <RxHamburgerMenu size={25} />
            </button>
          }
        </div>
      </nav>

      {
        displayMenu &&
        <div className="fixed top-0 left-0 bg-gray-700/60 h-[100vh] z-10 w-full text-gray-700 md:hidden">
          <div className="bg-white w-[80%] h-full">
            <div className="flex justify-between border-b border-b-gray-300 p-4">
              <div className="flex items-center lg:justify-center justify-left">
                <img width={100} src={logo} alt="Phoenix Electricals"></img>
              </div>
              <button className="md:hidden" onClick={() => setDisplayMenu(false)}>
                <RxCross1 size={25} />
              </button>
            </div>
            <div className="">
              <ul className="py-1" aria-labelledby="user-menu-button">
                {
                  menuItems.map(menuItem => {
                    const textColor = isMenuItemActive(menuItem.href) ? 'text-primary' : 'text-gray-700'
                    return (
                      <li key={menuItem.href} className="border-b border-gray-200 py-1">
                        <a
                          href={menuItem.href}
                          className={`block px-4 py-2 ${textColor} hover:bg-gray-100`}>
                          {menuItem.title}
                        </a>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default Header;
