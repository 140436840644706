import React from "react";
import ServicesImages from "../Assets/images/Services";

const Services = () => {
  const services = [
    {
      name: "EHV, 33/11, 33/22, 22/11 Substations",
      description: "Installation and commissioning of new EHV, 33/11, 33/22, and 22/11 substations.",
    },
    {
      name: "33KV, 22 KV and 11 KV Bay",
      description: "Efficient and reliable 33KV, 22KV, and 11KV bay installation and maintenance services for seamless power distribution - all in one place."
    },
    {
      name: "Distribution transformer Centers",
      description: "Streamlined installation and maintenance services for Distribution Transformer Centers to ensure optimal power supply and distribution.",
    },
    {
      name: "Overhead Lines",
      description: "Expert installation and maintenance services for efficient and reliable overhead lines to ensure uninterrupted power transmission and distribution.",
    },
    {
      name: "Underground Cables",
      description: "Efficient installation and maintenance services for underground cables, ensuring uninterrupted power supply and distribution.",
    },
    {
      name: "GIS substations",
      description: "Cutting-edge GIS substation installation and maintenance services to enhance power reliability, safety and efficiency.",
    },
    {
      name: 'Liasoning',
      description: "Liasoning for necessary project approvals from Utilities, PWD, Forest and Electrical Inspector.",
    },
    {
      name: 'Solar Solutions',
      description: "We provide cost effective solar solutions for commercial and resedential projects.",
    }
  ]
  return (
    <div className="px-5 md:px-10 py-5 md:py-10">
      <div className="flex justify-center pb-4">
        <h1 className="self-center lg:text-5xl text-4xl font-extrabold text-primary">
          OUR SERVICES
        </h1>
      </div>
      {/* Services Image Section */}
      <div className="py-8">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          {
            ServicesImages.map((image, idx) => {
              return (
                <div className="relative mb-4 sm:mb-0 sm:w-1/3" key={idx}>
                  <img src={image.src} alt="Image 1" className="w-full shadow-lg" />
                  <div className="absolute bottom-0 left-0 w-full bg-gray-800 bg-opacity-70 py-3">
                    <h3 className="text-white text-center text-xl font-bold">{image.title}</h3>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 my-4">
        {
          services.map((service, idx) => {
            return (
              <div className="bg-gray-100 rounded-lg shadow-lg p-6 text-center" key={idx}>
                <h3 className="text-2xl font-bold">{service.name}</h3>
                <p className="mt-2 text-gray-600">{service.description}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default Services;
