import React, { useState } from "react";
import { sendMessage } from "../services";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const ValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too short name!')
    .required('Name is required!'),
  mobile: Yup.string()
    .min(10, 'Enter 10 digit number!')
    .max(10, 'Enter 10 digit number!')
    .required('Mobile is required!'),
  email: Yup.string().email('Enter valid email!').required('Email is required!'),
  message: Yup.string().required('Message can not be empity'),
});

const intialValues = {
  name: '',
  mobile: '',
  email: '',
  message: '',
};

const ContactForm = () => {
  const [values, setValues] = useState(intialValues);
  const [message, setMessage] = useState({
    text: '',
    color: 'text-black'
  });

  const onFieldChange = (e) => {
    const target = e.target;
    setValues({ ...values, [target.name]: target.value })
  }

  return (
    <>
      <div className='flex flex-col max-w-md min-w-sm sm:shadow-lg rounded-lg px-6 py-6'>
        <div className="py-4">
          <h1 className="self-center text-2xl font-extrabold text-primary">
            CONTACT FORM
          </h1>
          <p className="text-gray-700 pt-2">
            Have a question or feedback? Fill out our contact form and we'll get back to you as soon as possible.
          </p>
        </div>
        <div className="w-full">
          <Formik
            initialValues={values}
            validationSchema={ValidationSchema}
            enableReinitialize={true}
            onSubmit={(values, actions) => {
              sendMessage(values).then(response => {
                if (response.status === 200) {
                  setMessage({
                    text: "Form submitted successfully 🎉!",
                    color: "text-green-500",
                  })
                } else {
                  setMessage({
                    text: "Something went wrong ❌!",
                    color: "text-red-500",
                  })
                }
                setValues(intialValues);
                actions.resetForm(intialValues);
              })
            }}
          >
            {({ errors, touched }) => (
              <Form className="space-y-3">
                <div>
                  <label htmlFor="name" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Name</label>
                  <Field
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={onFieldChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Your name" required />
                  <div className='text-red-500 text-sm pt-1'>
                    {errors.name && touched.name ? errors.name : null}
                  </div>
                </div>
                <div>
                  <label htmlFor="email" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Email</label>
                  <Field
                    type="email"
                    name="email"
                    onChange={onFieldChange}
                    value={values.email} className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Your email" required />
                  <div className='text-red-500 text-sm pt-1'>
                    {errors.email && touched.email ? errors.email : null}
                  </div>
                </div>
                <div>
                  <label htmlFor="mobile" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">Mobile Number</label>
                  <Field
                    type="number"
                    name="mobile"
                    value={values.mobile}
                    onChange={onFieldChange}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Contact number" required />
                  <div className='text-red-500 text-sm pt-1'>
                    {errors.mobile && touched.mobile ? errors.mobile : null}
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label htmlFor="message" className="block mb-1 text-sm font-medium text-gray-900 dark:text-gray-400">Your message</label>
                  <Field
                    name="message"
                    component="textarea"
                    rows="5"
                    value={values.message}
                    onChange={onFieldChange}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Add a message..." />
                  <div className='text-red-500 text-sm pt-1'>
                    {errors.message && touched.message ? errors.message : null}
                  </div>
                </div>
                {
                  message.text &&
                  <div className={`${message.color} py-1`}>
                    {message.text}
                  </div>
                }
                <button
                  type="submit"
                  className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-primary sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">
                  Send message
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
