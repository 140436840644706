import React from "react";
import logo from "../Assets/images/logo.webp";
import * as routes from "../routes";

const Footer = () => {
  return (
    <footer className="bg-white shadow-lg dark:bg-gray-900 p-4 border-t-2">
      <div className="w-full mx-auto p-4 lg:container">
        <div className="flex flex-col lg:flex-row items-center justify-between space-y-4">
          <div>
            <a href={routes.HOME} className="flex items-center mb-4 sm:mb-0">
              <img src={logo} className="h-16 mr-3" alt="Flowbite Logo" />
            </a>
          </div>
          <div className="block  text-gray-500 text-center dark:text-gray-400">
            <span className=" text-gray-700 text-center">
              © {new Date().getFullYear() + " "}
              <a href={routes.HOME} className="hover:underline">
                Phoenix Electricals. All Rights Reserved.
              </a>
            </span>
          </div>
          <div>
            <ul className="flex flex-wrap  justify-center items-center mb-6 font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
              <li>
                <a href={routes.ABOUT_US} className="mr-4 hover:underline md:mr-6 ">
                  About Us
                </a>
              </li>
              <li>
                <a href={routes.CONTRACTS} className="mr-4 hover:underline md:mr-6">
                  Contracts
                </a>
              </li>
              <li>
                <a href={routes.CLIENTS} className="hover:underline mr-4 md:mr-6">
                  Clients
                </a>
              </li>
              <li>
                <a href={routes.CONTACTUS} className="hover:underline">
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
