import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import CarouselImages from "../Assets/images/HomeSlider";

const HomeSlider = () => {
    return (
        <>
            <div className="relative md:h-[80vh] h-[80vh]">
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showThumbs={false}
                    stopOnHover={true}
                    showStatus={false}
                    interval={3000}
                    showArrows={false}
                >
                    {
                        CarouselImages.map((image, idx) => {
                            return (
                                <img
                                    key={idx}
                                    src={image}
                                    className="w-full md:h-[80vh] h-[80vh] object-fill"
                                    alt="Phoenix Electricals"
                                />
                            )
                        })
                    }
                </Carousel>
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60 px-5 md:px-10 flex flex-col justify-center align-middle items-center">
                    <h3 className="text-white text-center text-5xl font-extrabold ">
                        TURNKEY ELECTRICAL CONTRACTOR
                    </h3>
                    <div className="text-primary font-bold text-sm md:text-xl hidden sm:flex flex-row mt-4 gap-x-2">
                        <div className="px-2 text-center">SUBSTATIONS</div>
                        <div className="bg-primary w-1 h-8"></div>
                        <div className="px-2 text-center">OVERHEAD LINES</div>
                        <div className="bg-primary w-1 h-8"></div>
                        <div className="px-2 text-center">UNDERGROUND CABLES</div>
                    </div>
                    <div className="bg-white p-4 rounded-md bg-opacity-80 w-[99%] md:w-[60%] my-6">
                        <p className="text-lg text-center">
                            A government approved electrical contractor providing a range of services for electrical distribution work including supply, erection, testing, commissioning, and consultancy.
                        </p>
                    </div>
                    <div className="mt-4">
                        <a
                            href="/contact-us"
                            className="bg-primary text-white py-4 px-6 text-lg text-semibold rounded focus:outline-none">
                            Contact us
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomeSlider;