import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, AboutUs, Contracts, ContactUs, Clients } from "./Pages";
import { Layout } from "./Components";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import * as routes from "./routes";

try {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 1.0,
    });
} catch (error) {
    console.log("Ignore: Sentry init issue!")
}

function App() {
    return (
        <Routes>
            <Route path={routes.HOME} element={<Layout />} >
                <Route path={routes.HOME} element={< Home />} />
                <Route path={routes.ABOUT_US} element={<AboutUs />} />
                <Route path={routes.CONTRACTS} element={<Contracts />} />
                <Route path={routes.CONTACTUS} element={<ContactUs />} />
                <Route path={routes.CLIENTS} element={<Clients />} />
            </Route>
        </Routes>
    );
}

export default App;
