import React from "react";

const Clients = () => {
  const clients = [
    {
      name: "Ravin Infra Pvt Ltd, Wada",
    },
    {
      name: "Essel Propack Ltd, Kudus"
    },
    {
      name: "BVG India"
    },
    {
      name: "Aspee Foundation Ltd"
    },
    {
      name: "Navyug Kisan Sansadhan Ltd"
    },
    {
      name: "Hyderabad Industries Ltd"
    },
    {
      name: 'KSL Industies Ltd'
    },
    {
      name: "Sunshine Pep Tech",
    },
    {
      name: "Ashtech India Pvt Ltd"
    },
    {
      name: "Hindustan Coca Cola Beverages Pvt Ltd"
    },
    {
      name: "Core Crushing Equipments Pvt Ltd"
    },
    {
      name: "Yogesh Stone Crushing Ltd"
    },
    {
      name: "Evershine Crushing Company"
    },
    {
      name: 'Gubbi Pavers Pvt Ltd'
    },
    {
      name: "US Pharma Pvt Ltd"
    },
    {
      name: "Sky Enterprises"
    },
    {
      name: "Mirah Group of Construction"
    },
    {
      name: "Abhinav Group of Construction"
    },
    {
      name: "Ultra Tech Ltd"
    },
    {
      name: "Green Village Resort"
    },
    {
      name: 'Kalyani Village Resort'
    },
    {
      name: "Shital Medicare Pvt Ltd"
    },
    {
      name: "Veltech Forging Pvt Ltd"
    },

    {
      name: "Paner Cement Ltd"
    },
    {
      name: "Bedekar and Sons Pvt Ltd"
    },
    {
      name: "Ambekar Beverages and Foods Pvt Ltd"
    },
    {
      name: "Krishna Containers and Chemicals Pvt Ltd"
    },
    {
      name: 'Tuskano Lockers'
    },
    {
      name: "Infinity Industries"
    },
    {
      name: "Bhartiya Alloy Steel Pvt Ltd"
    },
    {
      name: "Sky Tech Rolling Mill Pvt Ltd"
    },
    {
      name: "Jerai Fitness Pvt Ltd"
    }
  ]
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-4">
        {
          clients.map(client => {
            return (
              <div className="bg-gray-100 rounded-md shadow-md p-6 text-center">
                <h3 className="text-lg font-bold text-gray-700">{client.name}</h3>
              </div>
            )
          })
        }
      </div>
    </div>
  );
};

export default Clients;
