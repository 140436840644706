import React from "react";
import { HomeSlider, Services, ContactForm, ReachOut } from "../Components";
import { FaWhatsapp } from 'react-icons/fa';

const Home = () => {

  return (
    <>
      <HomeSlider />

      {/* Services Section */}
      <div id="services ">
        <Services /> 
      </div>

      {/* Statistics Section */}
      <div className="bg-black text-white px-5 md:px-10 py-5 md:py-10">
        <div className="flex flex-wrap justify-between w-full py-6">
          <div className="lg:w-1/2 w-full">
            <div className="text-primary text-xl">
              Our Statistics
            </div>
            <div className="lg:text-5xl text-4xl font-extrabold">
              Some Important Facts
            </div>
          </div>
          <div className="lg:w-1/2 w-full flex flex-wrap gap-3 lg:gap-12 lg:justify-center pt-6">
            <div className="flex flex-col lg:items-center mb-4">
              <div className="text-4xl font-extrabold">
                50+
              </div>
              <div className="text-xl">
                Residential Projects
              </div>
            </div>
            <div className="flex flex-col lg:items-center mb-4">
              <div className="text-4xl font-extrabold">
                200+
              </div>
              <div className="text-xl">
                Commercial Projects
              </div>
            </div>
            <div className="flex flex-col lg:items-center mb-4">
              <div className="text-4xl font-extrabold">
                100+
              </div>
              <div className="text-xl">
                Industrial Projects
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Contact Us*/}
      <div className="lg:flex md:px-10 px-5 py-5 md:py-10 gap-4">
        <div className="w-full lg:w-8/12"><ReachOut /></div>
        <div className="w-full lg:w-4/12 px-4 justify-center"><ContactForm /></div>
      </div>

      {/* Action Buttons */}
      <a href="https://wa.me/919049744130" target="_blank" rel="noreferrer" className="bg-primary hover:bg-secondary p-2 rounded-full w-12 h-12 fixed bottom-10 right-0 mr-4">
        <FaWhatsapp size={32} color="white" />
      </a>
    </>
  );
};

export default Home;