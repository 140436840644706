import image1 from "./slider1.webp"
import image2 from "./slider2.webp"
import image3 from "./slider3.webp"
import image4 from "./slider4.webp"
import image6 from "./slider6.webp"
import image7 from "./slider7.webp"

export default [
    image1,
    image2,
    image3,
    image4,
    image6,
    image7
]